<template>
	<div>
		<div id="show-overlay"></div>
		<Loader />
		<div class="wrapper">
			<!-- Sidebar  -->
			<SideBarStyle
				:items="verticalMenu"
				:horizontal="horizontal"
				:logo="logo"
				@toggle="sidebarMini"
			/>
			<div id="content-page" class="content-page" :class="horizontal ? 'ml-0' : ''">
				<!-- TOP Nav Bar -->
				<NavBarStyle1
					title="Dashboard"
					:homeURL="{ name: 'dashboard.home-4' }"
					@toggle="sidebarMini"
					:logo="logo"
					:horizontal="horizontal"
					:items="horizontalMenu"
				>
					<template slot="responsiveRight">
						<ul class="navbar-nav ml-auto navbar-list">
							<!-- <li class="nav-item">
                <a class="search-toggle iq-waves-effect language-title" href="#"><img :src="settingsStore.langState.image" alt="img-flaf" class="img-fluid mr-1" style="height: 16px; width: 16px;" /> {{ settingsStore.langState.title }} <i class="ri-arrow-down-s-line"></i></a>
                <div class="iq-sub-dropdown">
                  <a class="iq-sub-card" href="javascript:void(0)" v-for="(lang, i) in settingsStore.langOptionState" :key="`Lang${i}`" @click="langChange(lang)">
                    <img :src="lang.image" alt="img-flaf" class="img-fluid mr-2" />{{ lang.title }}
                  </a>
                </div>
              </li> -->
							<li class="nav-item iq-full-screen">
								<a href="#" class="iq-waves-effect" id="btnFullscreen">
									<i class="ri-fullscreen-line"></i>
								</a>
							</li>
							<li class="nav-item">
								<a href="#" class="search-toggle iq-waves-effect">
									<i class="ri-notification-3-fill"></i>
									<span class="bg-danger dots"></span>
								</a>
								<div class="iq-sub-dropdown">
									<div class="iq-card shadow-none m-0">
										<div class="iq-card-body p-0">
											<div class="bg-primary p-3">
												<h5 class="mb-0 text-white">
													Notificaciones<small class="badge badge-light float-right pt-1">
														{{ notifications.length ?? 0 }}
													</small>
												</h5>
											</div>
											<a
												href="#"
												class="iq-sub-card"
												v-for="(item, index) in notifications"
												:key="index"
											>
												<div class="media align-items-center">
													<div class="">
														<img
															class="avatar-40 rounded"
															:src="require('../assets/images/user/05.jpg')"
															alt="img"
														/>
													</div>
													<div class="media-body ml-3">
														<h6 class="mb-0">{{ item?.type ?? "-" }}</h6>
														<p class="mb-0">
															{{ item.description }}
														</p>
													</div>
												</div>
											</a>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</template>
					<template slot="right">
						<ul class="navbar-list">
							<li>
								<a
									href="#"
									class="search-toggle iq-waves-effect d-flex align-items-center"
								>
									<img :src="userProfile" class="img-fluid rounded mr-3" alt="user" />
									<div class="caption">
										<h6 class="mb-0 line-height">{{ user?.name ?? "-" }}</h6>
										<span class="font-size-12">Disponible</span>
									</div>
								</a>
								<div class="iq-sub-dropdown iq-dropdown">
									<div class="iq-card shadow-none m-0">
										<div class="iq-card-body p-0">
											<div class="bg-primary p-3">
												<h5 class="mb-0 text-white line-height">
													Hola {{ user?.name ?? "-" }}
												</h5>
												<span class="text-white font-size-12">Disponible</span>
											</div>
											<router-link
												v-if="user.patient_id"
												class="iq-sub-card iq-bg-primary-hover"
												:to="{ name: 'patient.detail', params: { id: user.patient_id } }"
											>
												<div class="media align-items-center">
													<div class="rounded iq-card-icon iq-bg-primary">
														<i class="ri-file-line"></i>
													</div>
													<div class="media-body ml-3">
														<h6 class="mb-0">Mi Perfil</h6>
														<p class="mb-0 font-size-12">
															Ver detalles del perfil personal.
														</p>
													</div>
												</div>
											</router-link>
											<router-link
												v-if="user.patient_id"
												:to="{ name: 'patient.edit', params: { id: user.patient_id } }"
												class="iq-sub-card iq-bg-primary-success-hover"
											>
												<div class="media align-items-center">
													<div class="rounded iq-card-icon iq-bg-success">
														<i class="ri-profile-line"></i>
													</div>
													<div class="media-body ml-3">
														<h6 class="mb-0">Editar Perfil</h6>
														<p class="mb-0 font-size-12">Modifica tus datos personales</p>
													</div>
												</div>
											</router-link>
											<!-- <a href="#" class="iq-sub-card iq-bg-primary-danger-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-danger">
                            <i class="ri-account-box-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">Account Setting</h6>
                            <p class="mb-0 font-size-12">
                              Administre los parámetros de su cuenta.
                            </p>
                          </div>
                        </div>
                      </a> -->
											<!-- <a href="#" class="iq-sub-card iq-bg-primary-secondary-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-secondary">
                            <i class="ri-lock-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">Configuración de privacidad</h6>
                            <p class="mb-0 font-size-12">
                              Controla tus parámetros de privacidad
                            </p>
                          </div>
                        </div>
                      </a> -->
											<div class="d-inline-block w-100 text-center p-3">
												<a
													class="iq-bg-danger iq-sign-btn"
													href="javascript:void(0)"
													@click="logout"
													role="button"
												>
													Cerrar Sesión<i class="ri-login-box-line ml-2"></i>
												</a>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</template>
				</NavBarStyle1>
				<!-- TOP Nav Bar END -->
				<transition
					name="router-anim"
					:enter-active-class="`animated ${animated.enter}`"
					mode="out-in"
					:leave-active-class="`animated ${animated.exit}`"
				>
					<router-view />
				</transition>
				<FooterStyle1>
					<template v-slot:left>
						<li class="list-inline-item"><a href="#">Política de Privacidad</a></li>
						<li class="list-inline-item"><a href="#">Condiciones de uso</a></li>
					</template>
					<template v-slot:right>
						Copyright {{ currentYear }} <a href="#">Fisai</a> Todos los Derechos
						Reservados.
					</template>
				</FooterStyle1>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "../components/xray/loader/Loader";
import SideBarStyle from "../components/xray/sidebars/SideBarStyle";
import NavBarStyle1 from "../components/xray/navbars/NavBarStyle1";
import SideBarItems from "../FackApi/json/SideBarStarter";
import HorizontalItems from "../FackApi/json/HorizontalMenu";
import profile from "../assets/images/logo-fisai.png";
import { xray } from "../config/pluginInit";
import { mapStores, mapActions } from "pinia";
import { useSettingsStore } from "../stores/settings";
import api from "./../services/api";
import { Toast } from "../mixins/toast";
import constant from "../config/constant";

export default {
	components: {
		Loader,
		SideBarStyle,
		NavBarStyle1,
	},
	mounted() {
		this.getData();
		this.updateRadio();
		this.user = JSON.parse(localStorage.getItem("user"));
		const roles = [
			"Super Admin",
			"Doctor"
		]
		if(this.user.photo && this.user.photo != null) {
			this.userProfile = this.user.photo
		} else {
			this.userProfile = roles.includes(this.user.role) ? constant.defaultImage : constant.defaultUserImage;
		}
		const date = new Date();
		this.currentYear = date.getFullYear();
	},
	computed: {
		...mapStores(useSettingsStore),
	},
	watch: {},
	// sidebarTicket
	data() {
		return {
			notifications: [],
			currentYear: null,
			user: {
				patient_id: null,
			},
			horizontal: false,
			mini: false,
			darkMode: false,
			animated: { enter: "zoomIn", exit: "zoomOut" },
			animateClass: [
				{ value: { enter: "zoomIn", exit: "zoomOut" }, text: "Zoom" },
				{ value: { enter: "fadeInUp", exit: "fadeOutDown" }, text: "Fade" },
				{ value: { enter: "slideInLeft", exit: "slideOutRight" }, text: "Slide" },
				{ value: { enter: "rotateInDownLeft", exit: "rotateOutDownLeft" }, text: "Roll" },
			],
			horizontalMenu: HorizontalItems,
			verticalMenu: SideBarItems,
			userProfile: profile,
			logo: profile,
		};
	},
	methods: {
		async getData() {
			try {
				const { data } = await api.get("/notifications");
				this.notifications = data.data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: error.message,
				});
			}
		},
		updateRadio() {
			this.horizontal = this.settingsStore.horizontalMenuState;
			this.mini = this.settingsStore.miniSidebarState;
		},
		sidebarMini() {
			xray.triggerSet();
			this.settingsStore.miniSidebarAction();
			this.updateRadio();
		},
		logout() {
			localStorage.clear();
			this.$router.push({ name: "auth.sign-in" });
		},
		...mapActions(useSettingsStore, {
			langChangeState: "setLangAction",
			rtlAdd: "setRtlAction",
			rtlRemove: "removeRtlAction",
		}),
	},
};
</script>

<style>
@import url("../assets/css/custom.css");
</style>
